<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Questions
            </h3>
          </b-card-header>
          <b-row
            v-for="(section, index) in questions"
            :key="index"
          >
            <b-col v-if="index !== 'null'"><h4 class="p-1 mb-2 rounded bg-light">
              {{ index }}
            </h4></b-col>
            <b-col
              cols="12"
              class="px-2"
            >
              <b-row
                v-for="(question, index) in section"
                :key="index"
              >
                <b-col>
                  <b-form-group
                    :label="question.question"
                    :label-for="`question-${index}`"
                    :label-class="`h5`"
                  >
                    <b-form-input
                      v-if="question.input_type === 'Number'"
                      :id="`answer-${index}`"
                      v-model="question.programs_startupresponsetables[0].answer"
                      type="number"
                      placeholder="Your answer (in numbers)"
                      required
                    />
                    <b-form-textarea
                      v-else-if="question.input_type === 'Long text'"
                      :id="`answer-${index}`"
                      v-model="question.programs_startupresponsetables[0].answer"
                      placeholder="Your response"
                      required
                    />
                    <v-select
                      v-else-if="question.input_type === 'Drop Down'"
                      :id="`answer-${index}`"
                      v-model="question.programs_startupresponsetables[0].answer"
                      append-to-body
                      :options="['Option 1',
                                 'Option 2',
                                 'Option 3',
                                 'Option 4',]"
                      label="Choose from the following"
                      placeholder="Select from list"
                    />
                    <b-form-file
                      v-else-if="question.input_type === 'Document' || question.input_type === 'Upload File'"
                      :id="`answer-${index}`"
                      v-model="question.programs_startupresponsetables[0].answer"
                      accept="image/jpeg, image/png, image/gif"
                    />
                    <b-form-input
                      v-else
                      :id="`answer-${index}`"
                      v-model="question.programs_startupresponsetables[0].answer"
                      type="text"
                      placeholder="Your answer"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="$route.params.pid">
            <b-col class="px-2 d-flex align-items-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 mt-1 py-1"
                @click="updateAnswer"
              >
                <span>Submit Answers</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card></b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormFile, BRow, BCard, BFormTextarea, BOverlay, BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BButton,
    BCard,
    BFormTextarea,
    BOverlay,
    BCardHeader,
  },
  data() {
    return {
      applicationData: [],
      mutationLoading: false,
    }
  },
  computed: {
    questions() {
      this.applicationData.map(el => (el.programs_startupresponsetables.length === 0 ? el.programs_startupresponsetables.push({ answer: '' }) : el.programs_startupresponsetables))
      return this.groupByKey(this.applicationData, 'section')
    },
    answers() {
      const arr = []
      this.applicationData.map(r => arr.push(r.programs_startupresponsetables[0]))
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      arr.map(x => delete x.__typename)
      return arr
    },
  },
  methods: {
    groupByKey(array, key) {
      return Array.from(array).reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {})
    },
    updateAnswer() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_applicationresponsetable_insert_input!]!) {
          insert_programs_applicationresponsetable(objects: $answers, on_conflict: {constraint: programs_applicationresponsetable_pkey, update_columns: response}) {
            affected_rows
          }
        }`,
        variables: {
          answers: this.answers,
        },
        update: (store, { data: { insert_programs_applicationresponsetable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_applicationresponsetable.affected_rows ? 'Answers submitted successfully' : 'Failed to submit answers',
              icon: insert_programs_applicationresponsetable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_applicationresponsetable.affected_rows ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        {
            programs_assignmenttable_by_pk(id: ${this.$route.params.id}) {
              id
              programs_assignmentquestionstables {
                id
                programs_startupresponsetables(where: {participant_id: {_eq: ${this.$route.params.pid || 0}}}) {
                    id
                    answer
                }
                section
                question
                input_type
              }
            }
        }`
      },
      update: data => data.programs_assignmenttable_by_pk.programs_assignmentquestionstables,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
